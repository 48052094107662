import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import loadScript from 'load-script2'

import Layout from '../components/layout'
import '../styles/pages/_push-pads.scss'

class PushPadsPage extends Component {
  constructor (props) {
    super(props)
    this.page = props.data.datoCmsPushPadsPage
  }

  // I still don't really know how to use useEffect for this sort of thing, so it's back to classes we went
  async componentDidMount () {
    await loadScript('//app8.campus-site.com/assets/application/js/formgen.js')
  }

  render () {
    const page = this.page
    return (
      <Layout>
        <HelmetDatoCms seo={page.seoMetaTags}>
          {/* eslint-disable-next-line */}
          <script type="text/javascript">var form_id = 'OKDC35A8KS';</script>
        </HelmetDatoCms>
        <section
          className="hero is-large is-page-hero"
          style={{ backgroundImage: `url(${page.heroBackgroundImage.fixed.src})` }}
        >
          <div className="hero-body">
            <div className="container">
              <h1 className="title has-text-white has-text-weight-normal">
                { page.heroTitle }
              </h1>
            </div>
          </div>
        </section>
        <section className="hero is-secondary-hero">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h3 className="title is-size-4">
                {ReactHtmlParser(page.straplineNode.childMarkdownRemark.html)}
              </h3>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="content content-pushpads">
                {ReactHtmlParser(page.contentNode.childMarkdownRemark.html)}
              </div>
            </div>
            <div className="column">
              <figure className="image">
                <Img fluid={page.contentImage.fluid} alt={page.contentImage.alt} />
              </figure>
            </div>
          </div>
        </div>
        <br />
        <section className="section">
          <div className="columns is-centered">
            <div className="column is-half has-text-left">
              <h3 className="subtitle">Submit an enquiry</h3>
              <hr/>
              <div className="form">
                <div id="form_OKDC35A8KS" />
              </div>
            </div>
          </div>
        </section>
        <br/>
        <section className="section">
          <div className="level">
            {page.files.map((file, i) => (
              <div key={i} className="level-item has-text-centered">
                <div>
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-large is-outlined is-grey has-text-weight-medium download-button"
                    download>
                    {file.title}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
        <br/>
      </Layout>
    )
  }
}

PushPadsPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default PushPadsPage

export const query = graphql`
  query PushPadsQuery {
    datoCmsPushPadsPage {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed (
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fit: "crop"
            crop: "focalpoint"
          }
        ) {
          src
        }
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      contentImage {
        alt
        title
        fluid(
          maxWidth: 1080
          maxHeight: 400
          imgixParams: { auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      straplineNode {
        childMarkdownRemark {
          html
        }
      }
      files {
        title
        filename
        url
      }
    }
  }
`
